<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">WAREHOUSE</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <div class="flex justify-space-between mv-10">
        <div class="box left center">
          <el-input placeholder="Search"
                    size="small"
                    ref="search"
                    v-model="search"
                    style="width: 200px;"
                    @input="handleSearchInput"
                    class="box center"></el-input>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          background
          :pager-count="5"
          layout="total, sizes, prev, pager, next"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="pageSize"
          :total="filtered.length">
        </el-pagination>
        <div class="box right center">
          <el-button @click="addRow()" size="small">
              Create Item
          </el-button>
        </div>
    </div>
    <div :style="{width: tableWidth + 'px'}">
      <el-table
        @filter-change="handleFilterChange"
        :row-class-name="tableRowClassName"
        :max-height="tableHeight"
        :data="paginated">
        <el-table-column
          width="180"
          prop="name"
          label="Name">
        </el-table-column>
        <el-table-column
          width="180"
          prop="description"
          label="Description">
        </el-table-column>
        <el-table-column
          class-name="bg-primary-light"
          label-class-name=""
          width="80"
          prop="count"
          label="Count">
        </el-table-column>
        <el-table-column
          width="80"
          prop="lowerLimit"
          label="Limit">
        </el-table-column>
        <el-table-column
          width="80"
          prop="cost"
          label="Price">
        </el-table-column>
        <el-table-column
          width="120"
          prop="category"
          column-key="category"
          :filters="categoriesForFilter"
          :filter-method="filterCategoriesMethod"
          filter-placement="bottom-end"
          label="Category">
          <template #default="scope">
            {{getCategoryNameById[scope.row.category]}}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="Skates">
          <template #default="scope">
            <div v-if="getCategoryNameById[scope.row.category] === 'Skates'">
              <div class="fs-12" v-for="(value, name, index) in scope.row.skates" :key="index">
                {{name}}: {{value}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          prop="company"
          column-key="company"
          :filters="companiesForFilter"
          :filter-method="filterCompaniesMethod"
          filter-placement="bottom-end"
          label="Company">
          <template #default="scope">
            {{getCompanyNameById[scope.row.company]}}
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          prop="barcode"
          label="Barcode">
        </el-table-column>
        <el-table-column
          label="is Rent">
              <template #default="scope">
                <el-switch size="small" v-model="scope.row.isRent" disabled></el-switch>
              </template>
        </el-table-column>
        <el-table-column width="110">
          <template slot-scope="scope">
              <div class="flex gaps">
                  <el-button @click.native.prevent="editRow(scope.row)" type="text" size="small">
                      Edit
                  </el-button>
                  <el-button @click.native.prevent="openTrans(scope.row._id)" type="text" size="small">
                      Trans
                  </el-button>
                  <!-- <el-button @click.native.prevent="openPurchase(scope.row.uid)" type="text" size="small">
                      Purchase
                  </el-button>
                  <el-button @click.native.prevent="openInventory(scope.row.uid)" type="text" size="small">
                      Inventory
                  </el-button> -->
              </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Warehouse',

  data () {
    return {
      dataWarehouse: [],
      search: '',
      currentPage: 1,
      pageSize: 10,
      filtersCompany: [],
      filtersCategory: []
    }
  },

  computed: {
    ...mapState({
      warehouse: state => state.warehouse.warehouse,
      innerHeight: state => state.nav.innerHeight,
      innerWidth: state => state.nav.innerWidth
    }),
    ...mapGetters([
      // 'items',
      'companiesForSelect',
      'companiesForFilter',
      'categoriesForSelect',
      'categoriesForFilter',
      'getCompanyNameById',
      'getCategoryNameById',
      'getCompanyIdByName',
      'getCategoryIdByName'
    ]),
    filterAll () {
      return this.dataWarehouse
        .filter(c => {
          return this.filtersCompany.length ? this.filtersCompany.includes(this.getCompanyNameById[c.company]) : true
        })
        .filter(c => {
          return this.filtersCategory.length ? this.filtersCategory.includes(this.getCategoryNameById[c.category]) : true
        })
    },
    filtered () {
      return this.filterAll.filter(item => {
        return this.search.length < 2 ||
       (item.hasOwnProperty('name') && item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('barcode') && item.barcode.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('description') && item.description.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
      })
    },
    paginated () {
      return this.filtered.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    tableHeight () {
      return this.innerHeight - 181
    },
    tableWidth () {
      return this.innerWidth - 30
    }
  },

  methods: {
    handleFilterChange (filters) {
      if (filters.hasOwnProperty('company')) {
        this.filtersCompany = filters.company
      }
      if (filters.hasOwnProperty('category')) {
        this.filtersCategory = filters.category
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.lowerLimit > row.count) {
        return 'warning-row'
      } else {
        return ''
      }
    },
    handleSizeChange (val) {
      this.pageSize = val
      localStorage.setItem('pageSize', val)
    },
    handleSearchInput () {},
    filterCategoriesMethod (value, row) {
      let id = this.getCategoryIdByName[value]
      return row.category.includes(id)
    },
    filterCompaniesMethod (value, row) {
      let id = this.getCompanyIdByName[value]
      return row.company.includes(id)
    },
    // deleteRow (row, index) {
    //   this.$store.dispatch('removeItem', row._id)
    //     .then(() => {
    //       this.dataWarehouse.splice(index, 1)
    //     })
    // },
    editRow (row) {
      this.$onCommandParams('editItem', { id: row._id })
    },
    addRow () {
      this.$onCommandParams('createItem')
    },
    openTrans (id) {
      this.$onCommandParams('transaction', { id })
    },
    openPurchase (id) {
      this.$onCommandParams('purchase', { id })
    },
    openInventory (id) {
      this.$onCommandParams('inventory', { id })
    }
  },
  created () {
    if (localStorage.getItem('pageSize') !== null) {
      this.pageSize = Number(localStorage.getItem('pageSize'))
    }
  },
  mounted () {
    this.dataWarehouse = this.warehouse.map((x) => Object.assign({}, x))
  }
}
</script>

<style lang="scss">
</style>
